import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GetMySuppliersDocument } from '@/graphql/purchase-plus/generated/getMySuppliers.generated'
import { Supplier } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import ComboboxServer from '@/modules/shared/components/combobox/server/ComboboxServer'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

export interface PureSupplierRelationshipsSelectionProps {
  onSelected: (e: Supplier | null) => void
  defaultValue?: Supplier
  onChipRemove?: () => void
  placeholder?: string
  hasError?: boolean
  errorMessage?: string
  floating?: boolean
  loading?: boolean
  showChips?: boolean
  disabled?: boolean
  apSystemId?: number
}

export function PureSupplierRelationshipsSelection({
  onSelected,
  placeholder,
  hasError,
  errorMessage,
  floating,
  onChipRemove,
  defaultValue,
  loading,
  showChips = true,
  disabled,
  apSystemId,
}: PureSupplierRelationshipsSelectionProps) {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const { data, networkStatus, fetchMore } = useQuery(GetMySuppliersDocument, {
    variables: {
      first: 25,
      after: null,
      enabled: true,
      searchText: searchText,
      sort: 'name ASC',
      accountsPayableSystemId: apSystemId,
    },
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })
  return (
    <ComboboxServer
      testId="pure-supplier-relationships-selection"
      networkStatus={networkStatus}
      placeholder={placeholder || t('general.selectSupplierPlaceholder', 'Select a Supplier...')}
      keyExtractor={(e) => String(e?.id)}
      onDisplay={(e) => String(e.name)}
      items={extractEdges<Supplier>(data?.accountsPayableSystem?.suppliers)}
      onInputChange={(e) => setSearchText(e)}
      onChipRemove={showChips ? onChipRemove : undefined}
      onSelected={onSelected}
      hasError={hasError}
      loading={loading}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      floating={floating}
      disabled={disabled}
      onFetchMore={() =>
        fetchMore({
          variables: {
            after: data?.accountsPayableSystem?.suppliers?.pageInfo.endCursor,
          },
        })
      }
      hasMore={Boolean(data?.accountsPayableSystem?.suppliers?.pageInfo.hasNextPage)}
    />
  )
}
