export const SUPPORT_LINK = 'https://learn.purchaseplus.com'
export const HELP_CENTER = SUPPORT_LINK
export const HELP_LINK_PRODUCT_IMPORT_UTILITY =
  'https://marketboomer.atlassian.net/wiki/spaces/MAR/pages/1949990926/Product+Import+Utility+CSV+Template'
export const HELP_LINK_RELATED_DOCUMENTS = SUPPORT_LINK
export const HELP_LINK_INVOICE_LINES =
  'https://learn.purchaseplus.com/articles/link-invoice-lines-to-purchase-order-lines'
export const HELP_LINK_PO_CONSOLIDATION = 'https://learn.purchaseplus.com/articles/consolidate-purchase-orders'
export const HELP_LINK_RECEIVING_GOODS = 'https://learn.purchaseplus.com/articles/receive-goods-from-a-purchase-order'
export const HELP_LINK_REQUISITION_SHOPPING = 'https://learn.purchaseplus.com/articles/basic-shopping'
export const HELP_LINK_DRAFT_REQUISITION = 'https://learn.purchaseplus.com/articles/view-draft-requisitions'
export const HELP_LINK_PRODUCT_CREATION = 'https://learn.purchaseplus.com/articles/create-a-new-product'
export const HELP_LINK_CONTACT_SUPPORT_TEAM = 'https://www.purchaseplus.com/contact-us'
export const HELP_LINK_NEW_PURCHASE_PLUS_PROJECT = SUPPORT_LINK
export const HELP_LINK_CREATE_USER = 'https://learn.purchaseplus.com/articles/creating-and-managing-users'
export const HELP_LINK_CREATE_BUY_LIST = 'https://learn.purchaseplus.com/articles/create-a-new-buy-list'
export const HELP_LINK_CREATE_CATALOG = 'https://learn.purchaseplus.com/articles/create-a-self-managed-catalog'
export const HELP_LINK_CREATE_INVOICE = 'https://learn.purchaseplus.com/articles/create-a-new-invoice'
export const HELP_LINK_CREATE_INVOICE_EXPORT =
  'https://learn.purchaseplus.com/articles/create-a-batch-invoice-and-credit-note-export-file'
export const HELP_LINK_CREATE_CREDIT_NOTE = 'https://learn.purchaseplus.com/articles/introduction-to-credit-notes'
export const HELP_LINK_CREATE_REPORT = 'https://learn.purchaseplus.com/articles/run-a-report'
export const HELP_LINK_CREATE_REQUISITION = 'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_PENDING_INVOICES = SUPPORT_LINK
export const HELP_LINK_PRODUCT_RULES = SUPPORT_LINK
export const HELP_LINK_INVOICE_EXPORT_CENTER =
  'https://learn.purchaseplus.com/articles/create-a-batch-invoice-and-credit-note-export-file'
export const HELP_LINK_BARCODES = SUPPORT_LINK
export const HELP_LINK_DRAFT_REQUISITIONS_DASHBOARD =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_ALL_DRAFT_REQUISITIONS_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/view-draft-requisitions'
export const HELP_LINK_ALL_DRAFT_REQUISITIONS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/view-draft-requisitions'
export const HELP_LINK_ALL_REQUISITION_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_ALL_REQUISITION_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_ALL_AWAITING_MY_APPROVAL_REQUISITIONS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/approve-a-requisition'
export const HELP_LINK_REQUISITION_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_REQUISITION_VIEW_CART_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_REQUISITION_PURCHASE_ORDERS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_REQUISITION_INVOICES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-requisitions'
export const HELP_LINK_ALL_PURCHASE_ORDERS_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-purchase-orders'
export const HELP_LINK_ALL_PURCHASE_ORDERS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-purchase-orders'
export const HELP_LINK_PURCHASE_ORDER_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-purchase-orders'
export const HELP_LINK_PURCHASE_ORDER_INVOICES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-purchase-orders'
export const HELP_LINK_PURCHASE_ORDERS_SEND_EVENTS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/send-a-purchase-order'
export const HELP_LINK_ALL_RECEIVING_NOTES_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-receiving-notes'
export const HELP_LINK_RECEIVING_NOTE_PRODUCT_SUMMARY_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-receiving-notes'
export const HELP_LINK_ALL_INVOICES_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-invoices'
export const HELP_LINK_ALL_INVOICES_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-invoices'
export const HELP_LINK_ALL_AWAITING_MY_APPROVAL_INVOICES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/approve-an-invoice'
export const HELP_LINK_ALL_CREDIT_NOTES_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-credit-notes'
export const HELP_LINK_ALL_CREDIT_NOTES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-credit-notes'
export const HELP_LINK_PENDING_INVOICES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_PENDING_INVOICES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_INVOICE_AI_INBOX_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_INVOICE_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-invoices'
export const HELP_LINK_INVOICE_PURCHASE_ORDERS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/match-a-purchase-order-to-an-invoice'
export const HELP_LINK_INVOICE_CREATOR_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_CREDIT_NOTE_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-credit-notes'
export const HELP_LINK_ALL_BUY_LISTS_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-buy-lists'
export const HELP_LINK_ALL_BUY_LISTS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-buy-lists'
export const HELP_LINK_BUY_LIST_PRODUCTS_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-buy-lists'
export const HELP_LINK_BUY_LIST_IMPORTS = SUPPORT_LINK
export const HELP_LINK_BUY_LIST_EXPORTS = SUPPORT_LINK
export const HELP_LINK_ALL_CATALOGS_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-catalogs'
export const HELP_LINK_ALL_CATALOGS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-catalogs'
export const HELP_LINK_ALL_SUPPLIER_CATALOGS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_ALL_SUPPLIER_CATALOGS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_SUPPLIER_CATALOG_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_SUPPLIER_CATALOG_PRODUCTS_MORE_OPTIONS = SUPPORT_LINK
export const HELP_LINK_CATALOG_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-catalogs'
export const HELP_LINK_CATALOG_IMPORTS = 'https://learn.purchaseplus.com/articles/import-a-self-managed-catalog'
export const HELP_LINK_CATALOG_EXPORTS = SUPPORT_LINK
export const HELP_LINK_ALL_PRODUCTS_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-products'
export const HELP_LINK_ALL_PRODUCTS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-products'
export const HELP_LINK_PRODUCT_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-products'
export const HELP_LINK_PRODUCT_BARCODES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ALL_BARCODES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_ALL_BARCODES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ALL_PRODUCT_RULES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_ALL_PRODUCT_RULES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_MY_SUPPLIER_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-suppliers'
export const HELP_LINK_MY_SUPPLIER_CATALOGS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/view-supplier-catalogs'
export const HELP_LINK_MY_SUPPLIER_PURCHASE_ORDERS_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-suppliers'
export const HELP_LINK_MY_SUPPLIER_INVOICES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-suppliers'
export const HELP_LINK_MY_SUPPLIER_PRODUCT_RULES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ALL_SUPPLIER_DIRECTORY_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/connecting-with-a-registered-supplier'
export const HELP_LINK_ALL_SUPPLIER_DIRECTORY_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/connecting-with-a-registered-supplier'
export const HELP_LINK_ALL_STOCKTAKES_MORE_OPTION =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stocktakes'
export const HELP_LINK_STOCKTAKE_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stocktakes'
export const HELP_LINK_ALL_REPORTS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/run-a-report'
export const HELP_LINK_REPORT_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_ALL_REQUISITION_LINES_REPORT_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ALL_USERS_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-user-management'
export const HELP_LINK_ALL_USERS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-user-management'
export const HELP_LINK_USER_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-user-management'
export const HELP_LINK_ADMIN_CENTER_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MY_PURCHASERS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MY_SUPPLIERS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MY_PURCHASERS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MY_SUPPLIERS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_SEARCH_PRODUCTS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_IMPORTS_PRODUCTS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_IMPORTS_PRODUCTS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_PROCESS_PRODUCTS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AP_SYSTEMS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AP_SYSTEMS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AP_EXPORT_FORMATS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AP_EXPORT_FORMATS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_GQL_BUILDER_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_USERS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_USERS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_USER_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_RELATIONSHIPS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_RELATIONSHIPS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_RELATIONSHIP_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_ORGANISATION_HIERARCHIES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_ORGANISATION_HIERARCHIES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_ORGANISATION_HIERARCHY_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_RELATIONSHIP_CATALOGS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_RELATIONSHIP_CATALOGS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_CATALOGS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_CATALOGS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_EMAIL_ADDRESSES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_EMAIL_ADDRESSES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_TASKS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_PRODUCT_AI_SCANNING_TASK_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_PROMPTS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_PROMPTS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AI_SCANNING_PROMPT_ORGANISATION_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_CREATE_AI_PROMPT_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_AP_SYSTEM_TAX_CODE_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_STOCK_ITEM_SUBSTITUTE_PRODUCTS =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stock-items'
export const HELP_LINK_PRODUCT_SUBSTITUTE_PRODUCTS = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_PIPES_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_TRADE_PIPES_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ALL_MENUS_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-recipes-menus'
export const HELP_LINK_ALL_MENUS_MORE_OPTION = 'https://learn.purchaseplus.com/articles/introduction-to-recipes-menus'
export const HELP_LINK_PUBLIC_SHOP = SUPPORT_LINK // Inventory
export const HELP_LINK_SUPPLIER_PUBLIC_PRODUCTS = SUPPORT_LINK // Inventory
export const HELP_LINK_INVENTORY_STOCKTAKE_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stocktakes'
export const HELP_LINK_RECIPE_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-recipes'
export const HELP_LINK_INVENTORY_STOCKTAKE =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stocktakes'
export const HELP_LINK_IMPORT_SELF_MANAGED_CATALOG =
  'https://learn.purchaseplus.com/articles/import-a-self-managed-catalog'
export const HELP_LINK_CREATE_NEW_MENU = 'https://learn.purchaseplus.com/articles/introduction-to-recipes-menus'
export const HELP_LINK_ALL_INGREDIENTS_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/introduction-to-recipes-ingredients'
export const HELP_LINK_SUPPLIER_DIRECTORY_DETAILS_PAGE_HEADER =
  'https://learn.purchaseplus.com/articles/connecting-with-a-registered-supplier'
export const HELP_LINK_MENU_PAGE_HEADER = 'https://learn.purchaseplus.com/articles/introduction-to-recipes-menus'
export const HELP_LINK_MY_SUPPLIER_DESTINATIONS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_INVOICE_FLAGS_EXPLAINED = 'https://learn.purchaseplus.com/articles/invoice-flags-explained'
export const HELP_LINK_BUY_LIST_BUILDER = 'https://learn.purchaseplus.com/articles/add-products-to-a-buy-list'
export const HELP_LINK_CREATE_A_BUY_LIST_REQUISITION =
  'https://learn.purchaseplus.com/articles/create-a-requisition-from-a-buy-list'
export const HELP_LINK_MY_SUPPLIERS = 'https://learn.purchaseplus.com/articles/view-and-manage-suppliers'
export const HELP_LINK_STOCK_ITEMS = 'https://learn.purchaseplus.com/articles/introduction-to-inventory-stock-items'
export const HELP_LINK_STOCK_LOCATIONS =
  'https://learn.purchaseplus.com/articles/introduction-to-inventory-stock-locations'
export const HELP_LINK_TRANSFERS = 'https://learn.purchaseplus.com/articles/introduction-to-inventory-transfers'
export const HELP_LINK_STOCKTAKES = 'https://learn.purchaseplus.com/articles/introduction-to-inventory-stocktakes'
export const HELP_LINK_UTILITIES_IMPORTS_ORGANISATIONS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_IMPORTS_ORGANISATIONS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_ORGANISATION_IMPORT_UTILITY = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_REPORTS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_REPORT_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_REPORTS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_ALL_APPROVAL_WORKFLOWS_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_CREATE_A_NEW_APPROVAL_WORKFLOW = SUPPORT_LINK
export const HELP_LINK_APPROVAL_WORKFLOW_PAGE_HEADER = SUPPORT_LINK
export const HELP_LINK_APPROVAL_WORKFLOW_RANKS = SUPPORT_LINK
export const HELP_LINK_APPROVAL_WORKFLOW_RANK_APPROVERS = SUPPORT_LINK
export const HELP_LINK_UTILITIES_MANAGE_PRODUCT_CODES = SUPPORT_LINK
export const HELP_LINK_ORGANISATION_SETTINGS_ACCOUNTS_UPDATE = SUPPORT_LINK
export const HELP_LINK_PUBLIC_SHOP_PRODUCTS_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_PUBLIC_SHOP_CART_VIEW_MORE_OPTION = SUPPORT_LINK
export const HELP_LINK_CATALOG_VIEW_ASSIGNMENTS_MORE_OPTION = SUPPORT_LINK
