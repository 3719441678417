import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useCreateOrUpdatePartyProduct } from '@/modules/products/hooks/useCreateOrUpdatePartyProduct'
import { useCreateRequisitionLine } from '@/modules/requisitions/hooks/useCreateRequisitionLine'
import { generateShopPath } from '@/modules/requisitions/utils/generateShopPath'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { useCreateInformalProduct } from '@/modules/shared/hooks/useCreateInformalProduct'

export interface UseCreateAndAddProductProps {
  isUtility?: boolean
  quickAdd?: boolean
  refetch?: () => void
  onCloseModal?: () => void
  id?: string
}

export function useCreateAndAddProductRequisition({
  quickAdd,
  refetch,
  onCloseModal,
  id,
}: UseCreateAndAddProductProps) {
  const { currentOrganisation } = useSessionUser()
  const [createInformalProduct, { loading: createInformalProductLoading }] = useCreateInformalProduct()
  const [createRequisitionLine, { loading: createRequisitionLineLoading }] = useCreateRequisitionLine()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [createOrUpdatePartlyProduct] = useCreateOrUpdatePartyProduct()

  const onCreateAndAddProductRequisition = (input: CreateProductFormInputs) => {
    const {
      categoryId,
      brand,
      itemDescription,
      unitPrice,
      taxPercentage,
      quantity,
      itemMeasure,
      itemSize,
      itemPackName,
      itemSellPackName,
      itemSellQuantity,
      supplierId,
      code,
    } = input
    createInformalProduct({
      variables: {
        input: {
          categoryId,
          brand,
          itemDescription,
          locale: 'en',
          itemMeasure,
          itemSize,
          itemPackName: itemPackName === 'null' ? null : itemPackName,
          itemSellPackName,
          itemSellQuantity,
        },
      },
      onCompleted: async (data) => {
        const createdProductId = Number(data.createInformalProduct.informalProduct?.id)
        if (code) {
          await createOrUpdatePartlyProduct({
            variables: {
              input: {
                productId: createdProductId,
                organisationId: Number(currentOrganisation?.id),
                code: code,
              },
            },
          })
        }
        createRequisitionLine({
          variables: {
            input: {
              supplierId,
              productId: createdProductId,
              requisitionId: Number(id),
              unitPrice,
              taxPercentage,
              quantity,
              nextGenQuotes: !!currentOrganisation?.featureFlags?.useNextGenQuotes,
            },
          },
          onCompleted(newLineData) {
            if (quickAdd) {
              onCloseModal && onCloseModal()
              displayToast({ title: t('general.productAdded', 'Product Added') })
              refetch && refetch()
            } else {
              navigate(
                generateShopPath(
                  newLineData.createRequisitionLine.requisition?.id,
                  newLineData.createRequisitionLine.requisition?.advanced
                )
              )
            }
          },
        })
      },
    })
  }

  return { onCreateAndAddProductRequisition, loading: createInformalProductLoading || createRequisitionLineLoading }
}
