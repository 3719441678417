import { zodResolver } from '@hookform/resolvers/zod'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { Category } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateOrUpdatePartyProduct } from '@/modules/products/hooks/useCreateOrUpdatePartyProduct'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/useCurrentPurchaser'
import { Button } from '@/modules/shared/components/button/Button'
import {
  CreateProductFormInputs,
  CreateProductFormSchemaWithType,
} from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import CreateProductInformation from '@/modules/shared/components/create-product-modal/CreateProductInformation'
import CreateProductPreview from '@/modules/shared/components/create-product-modal/CreateProductPreview'
import CreateProductSellPack from '@/modules/shared/components/create-product-modal/CreateProductSellPack'
import CreateProductUnitSize from '@/modules/shared/components/create-product-modal/CreateProductUnitSize'
import CreateProductValues from '@/modules/shared/components/create-product-modal/CreateProductValues'
import { useCreateAndAddProductBuyList } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductBuyList'
import { useCreateAndAddProductCatalog } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductCatalog'
import { useCreateAndAddProductCreditNote } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductCreditNote'
import { useCreateAndAddProductGRNote } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductGRNote'
import { useCreateAndAddProductInvoice } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductInvoice'
import { useCreateAndAddProductRequisition } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductRequisition'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { useCreateInformalProduct } from '@/modules/shared/hooks/useCreateInformalProduct'
import { ProductListType } from '@/modules/shared/types/ProductListType'

export interface CreateProductModalProps {
  type: ProductListType
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  title: string
  id?: number
  refetch?: () => void
  quickAdd?: boolean
  isUtility?: boolean
}

export default function CreateProductModal({
  type,
  showModal,
  setShowModal,
  title,
  id,
  refetch,
  quickAdd,
  isUtility,
}: CreateProductModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [defaultUnitSize, setDefaultUnitSize] = useState(true)
  const [defaultSellPack, setDefaultSellPack] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)

  const { currentPurchaser } = useCurrentPurchaser()

  const [createOrUpdatePartlyProduct, { loading: createOrUpdatePartlyProductLoading }] = useCreateOrUpdatePartyProduct()
  const [createInformalProduct, { loading: createInformalProductLoading }] = useCreateInformalProduct()

  const formMethods = useForm<CreateProductFormInputs>({
    defaultValues: {
      taxPercentage: 0,
      itemSize: 1,
      itemSellQuantity: 1,
    },
    resolver: zodResolver(CreateProductFormSchemaWithType(type)),
  })

  const onCloseModal = () => {
    setShowModal(false)
    if (formMethods.formState.errors) formMethods.reset()
  }

  const { onCreateAndAddProductBuyList, loading: createAndAddProductBuyListLoading } = useCreateAndAddProductBuyList({
    quickAdd,
    refetch,
    onCloseModal,
    id: String(id),
  })
  const { onCreateAndAddProductCatalog, loading: createAndAddProductCatalogLoading } = useCreateAndAddProductCatalog({
    isUtility,
    quickAdd,
    refetch,
    onCloseModal,
    id: String(id),
  })
  const { onCreateAndAddProductGRNote, loading: createAndAddProductGRNoteLoading } = useCreateAndAddProductGRNote({
    id: String(id),
  })
  const { onCreateAndAddProductInvoice, loading: createAndAddProductInvoiceLoading } = useCreateAndAddProductInvoice({
    quickAdd,
    refetch,
    onCloseModal,
    id: String(id),
  })
  const { onCreateAndAddProductCreditNote, loading: createAndAddProductCreditNoteLoading } =
    useCreateAndAddProductCreditNote({
      quickAdd,
      refetch,
      onCloseModal,
      id: String(id),
    })
  const { onCreateAndAddProductRequisition, loading: createAndAddProductRequisitionLoading } =
    useCreateAndAddProductRequisition({ quickAdd, refetch, onCloseModal, id: String(id) })

  const onSubmit: SubmitHandler<CreateProductFormInputs> = (data) => {
    const {
      categoryId,
      brand,
      itemDescription,
      itemMeasure,
      itemSize,
      itemPackName,
      itemSellPackName,
      itemSellQuantity,
      code,
      externalId,
    } = data
    if (type === ProductListType.AllProducts) {
      createInformalProduct({
        variables: {
          input: {
            categoryId,
            brand,
            itemDescription,
            locale: 'en',
            itemMeasure,
            itemSize,
            itemPackName: itemPackName === 'null' ? null : itemPackName,
            itemSellPackName,
            itemSellQuantity,
            externalId,
          },
        },
        onCompleted: async (data) => {
          const createdProductId = Number(data.createInformalProduct.informalProduct?.id)
          if (code) {
            await createOrUpdatePartlyProduct({
              variables: {
                input: {
                  productId: createdProductId,
                  organisationId: Number(currentPurchaser?.id),
                  code: code,
                },
              },
            })
          }
          onCloseModal()
          displayToast({ title: t('general.productCreated', 'Product Successfully Created') })
          navigate(generatePath('/products/:productId/details', { productId: String(createdProductId) }))
        },
      })
    } else if (type === ProductListType.AddCatalogProduct) {
      onCreateAndAddProductCatalog(data)
    } else if (type === ProductListType.AddBuyListProduct) {
      onCreateAndAddProductBuyList(data)
    } else if (type === ProductListType.AddInvoiceLine) {
      onCreateAndAddProductInvoice(data)
    } else if (type === ProductListType.AddReceivingNoteProduct) {
      onCreateAndAddProductGRNote(data)
    } else if (type === ProductListType.AddRequisitionProduct) {
      onCreateAndAddProductRequisition(data)
    } else if (type === ProductListType.AddCreditNoteLine) {
      onCreateAndAddProductCreditNote(data)
    }
  }

  const getSubmitButtonText = () => {
    switch (type) {
      case ProductListType.AddCatalogProduct:
        return t('general.addToCatalog', 'Add to Catalog')
      case ProductListType.AddBuyListProduct:
        return t('buyLists.builder.addToBuyList', 'Add to Buy List')
      case ProductListType.AddInvoiceLine:
        return t('general.addToInvoice', 'Add to Invoice')
      case ProductListType.AddReceivingNoteProduct:
        return t('general.addProduct', 'Add Product')
      case ProductListType.AddRequisitionProduct:
        return t('general.addToRequisition', 'Add to Requisition')
      case ProductListType.AllProducts:
        return t('general.createProduct', 'Create Product')
      case ProductListType.AddCreditNoteLine:
        return t('general.addToCreditNote', 'Add to Credit Note')
      default:
        return ''
    }
  }

  const loading =
    createInformalProductLoading ||
    createAndAddProductCatalogLoading ||
    createAndAddProductBuyListLoading ||
    createAndAddProductInvoiceLoading ||
    createAndAddProductGRNoteLoading ||
    createAndAddProductRequisitionLoading ||
    createOrUpdatePartlyProductLoading ||
    createAndAddProductCreditNoteLoading

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="overflow-hidden">
        <FormProvider {...formMethods}>
          <Modal.Panel
            className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
            data-testid="create-product-modal"
          >
            <Modal.Title title={title} onCloseModal={onCloseModal} />

            <Modal.Body>
              <section className="rounded-md bg-gray-200 px-4 py-3 text-sm">
                {currentPurchaser?.isAPurchaser
                  ? t(
                      'purchaser.myProducts.createNewProductPurchaserDesc',
                      'Here you can create an entirely new product to add to the All Products area. You might need to do this so that the product can be added to a Buy List, Catalog, or used in a Custom Requisition. {{currentPurchaser}} will be set as the owner of any product you create here.',
                      { currentPurchaser: currentPurchaser?.name }
                    )
                  : t(
                      'purchaser.myProducts.createNewProductSupplierDesc',
                      'Here you can create an entirely new product to add to the All Products area. You might need to do this so that you can add the product to one or more Catalogs. Your Organisation {{currentPurchaser}} will be set as the owner of any product you create here.',
                      { currentPurchaser: currentPurchaser?.name }
                    )}
              </section>
              <div className="mt-5 space-y-5 text-sm">
                <CreateProductInformation setSelectedCategory={setSelectedCategory} />
                <CreateProductUnitSize defaultUnitSize={defaultUnitSize} setDefaultUnitSize={setDefaultUnitSize} />
                <CreateProductSellPack defaultSellPack={defaultSellPack} setDefaultSellPack={setDefaultSellPack} />
                <CreateProductValues type={type} />
                <CreateProductPreview selectedCategory={selectedCategory} />
              </div>
            </Modal.Body>
            <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
              <Button
                className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
                onClick={onCloseModal}
                type="button"
                data-testid="cancel-button"
              >
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
                type="submit"
                loading={loading}
                data-testid="submit-button"
              >
                {getSubmitButtonText()}
              </Button>
            </Modal.Footer>
          </Modal.Panel>
        </FormProvider>
      </form>
    </Modal>
  )
}
